<template>
    <modal :visible.sync="isVisible" :close-action="null" :is-shadow-closable="false" class="modal--contract-delete">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <div :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Confirmez vous l’arrêt du contrat ?
            </span>
          </div>
        </section>
        <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />

        <p class="mt-3 text-center text-dark-lighter">
          Le contrat va être arrêté.
        </p>

        <div class="flex justify-center mt-10">
          <form @submit.prevent="onSubmit">
            <div class="flex flex-col relative">
              <label
                for="endDate"
                :class="['form-label', 'font-bold', 'flex', errors.endDate ? 'text-error' : null]"
              >
                <span>Date de fin du contrat<span>*</span></span>
                <icon
                  icon="infos-primary-bluer"
                  size="md"
                  :class="['ml-3', errors.endDate ? 'text-error' : null]"
                />
              </label>
              <Calendar
                v-model="endDate"
                show-icon
                placeholder="Date de fin du contrat"
                :class="['w-full']"
                dateFormat="dd/mm/yy"
                showButtonBar
              />
              <div v-show="errors.endDate" :class="['text-sm']" :style="{ color: 'red' }">
                {{ errors.endDate }}
              </div>
            </div>
            <div class="flex flex-col mt-3">
              <label
                for="title"
                :class="['form-label', 'font-bold', errors.revisionNote ? 'text-error' : null]"
              >
                Note sur les modifications du contrat*
              </label>
              <input
                id="revisionNote"
                name="revisionNote"
                v-model="revisionNote"
                type="text"
                required
                :class="['form-input']"
              />
              <div v-show="errors.revisionNote" :class="['text-sm']" :style="{ color: 'red' }">
                {{ errors.revisionNote }}
              </div>
            </div>

            <div class="flex justify-center gap-x-5 my-10">
              <button type="button" class="btn btn-outline--primary-bluer" @click="isVisible = false">
                Annuler
              </button>
              <button type="submit" class="btn btn-secondary">
                VALIDER
              </button>
            </div>
          </form>
        </div>

      </div>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import axios from '@/axios'
import $axios from "@/axios";

export default {
  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    originalEndDate: {
      type: String,
      default: ''
    },
  },

  watch: {
    isOpen(newVal) {
      this.isVisible = newVal
    },
    isVisible(newVal) {
      this.$emit('update:isOpen', newVal)
    },
    originalEndDate(newVal) {
      this.endDate = newVal
    }
  },

  data() {
    return {
      isVisible: false,
      loading: false,
      endDate: null,
      revisionNote: null,
      isErrorsOnForm: false,
      errors: {
        endDate: null,
        revisionNote: null,
      }
    }
  },

  async beforeMount() {
    this.isVisible = this.isOpen
  },

  methods: {
    /**
     * Check Errors method
     * @return {void}
     */
    async checkErrors(){
      this.isErrorsOnForm = false
      this.errors = {
        endDate: null,
        revisionNote: null
      }
      if (!this.endDate || !this.revisionNote) {
        this.isErrorsOnForm = true
        if (!this.endDate) {
          this.errors.endDate = 'Champ requis'
        }
        if (!this.revisionNote) {
          this.errors.revisionNote = 'Champ requis'
        }
      }
    },
    async onSubmit() {
      await this.checkErrors()
      if (this.isErrorsOnForm) return
      this.isVisible = false
      this.$emit('submit', this.revisionNote, this.endDate)
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    width: 100%;
    max-width: 350px;
  }
</style>
