<template>
  <layer-base v-if="isOpen" class="layer--notifications">
    <template #header>
      <h2 :class="['layer__titre']">
        Notifications
      </h2>
      <div class="flex">
        <icon
          icon="parametres"
          size="sm"
        />
        <button @click="handleGoCenterPreferences()">Paramètres</button>
      </div>
    </template>
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
    >
      <span class="dot" />
      <div class="notification__content">
        <h3 class="notification__title">
          {{ notification.title }}
        </h3>
        <div class="notification__description" v-html="notification.description"></div>
        <a
          v-if="notification.link"
          :href="notification.link"
          :target="isExternalLink(notification.link) ? '_blank' : '_self'"
          class="notification__link"
        >
          {{ notification.linkLabel }}
        </a>
      </div>
      <icon
        icon="fermer-blanc"
        size="sm"
        @click.native="setReadingStatus(notification.id)"
        class="cursor-pointer"
      />
    </div>
  </layer-base>
</template>

<script>
import LayerBase from '@/components/layers/LayerBase.vue'

export default {
  components: { LayerBase },

  computed: {
    isOpen() {
      return this.$store.state.layers.isLayerNotificationsOpen
    },

    notifications(){
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      return this.$store.state.notifications.notifications?.filter((notification) => {
        if (notification.date) {
          const notificationDate = new Date(notification.date)
          return notificationDate >= thirtyDaysAgo
        }
        return false
      })
    }
  },

  watch: {
    isOpen() {
      this.$store.dispatch('notifications/getNotifications')
    }
  },

  methods: {
    isExternalLink(url) {
      const a = document.createElement('a');
      a.href = url;
      return a.hostname !== window.location.hostname;
    },
    handleGoCenterPreferences() {
      this.$router.push("/centre-de-preferences").catch(()=>{});
    },
    setReadingStatus(notificationId) {
      this.$store.dispatch('notifications/setReadingStatus', notificationId)
    }
  },

  beforeMount(){
    this.$store.dispatch('notifications/getNotifications')
  },
}
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  column-gap: 15px;
  padding: 20px 15px;
  border-top: 1px solid #E4E8ED;
  &:last-child { border-bottom: 1px solid #E4E8ED; }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-top: 8px;
    background-color: #FFF;
    border-radius: 50px;
  }

  .notification__content {
    flex: 1;

    .notification__title {
      font-size: 16px;
    }

    .notification__description {
      font-size: 14px;
    }
  }
}
</style>
