<template>
  <div :class="['select', 'relative']">
    <div class="autosuggest-container">
      <vue-autosuggest
        v-model="query"
        :suggestions="filteredOptions"
        @selected="onSelected"
        @input="onInputChange"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{
          id:'autosuggest__input',
          class: [
            'btn',
            'btn-select',
            'cursor-pointer',
            disabled ? 'disabled' : null,
            error ? 'error' : null,
          ],
          placeholder:'Recherchez et selectionnez...'
        }"
      >
        <div
          slot-scope="{suggestion}"
          style="display: flex; align-items: center;"
        >
          <div>
            {{suggestion.item.label}}
          </div>
        </div>
      </vue-autosuggest>
    </div>
    <div v-show="error" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-18px' }">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    VueAutosuggest
  },

  props: {
    suggestions: {
      type: [],
      default: []
    },
    error: {
      type: [String, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      query: "",
      selected: "",
    };
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data.filter(option => {
            return option.label?.toLowerCase().indexOf(this.query?.toLowerCase()) > -1;
          })
        }
      ];
    }
  },
  methods: {
    onSelected(item) {
      this.selected = item?.item;

      this.$emit('select', this.selected?.id)
    },

    onInputChange(text) {
      this.$emit('input', text)
    },

    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.label;
    },
  }
}
</script>
